@font-face {
    font-family: "Sora";
    src: url("../assets/fonts/sora-v1-latin-700.woff2") format("woff2"),
        url("../assets/fonts/sora-v1-latin-700.woff") format("woff");
    font-weight: 700;
    font-style: bold;
}

@font-face {
    font-family: "Sora";
    src: url("../assets/fonts/sora-v1-latin-regular.woff2") format("woff2"),
        url("../assets/fonts/sora-v1-latin-regular.woff") format("woff");
    font-weight: 400;
    font-style: Regular;
}

@font-face {
    font-family: "Sora";
    src: url("../assets/fonts/sora-v1-latin-800.woff2") format("woff2"),
        url("../assets/fonts/sora-v1-latin-800.woff") format("woff");
    font-weight: 800;
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* div {
    box-sizing: border-box;
}
